define("discourse/plugins/folksy-store-name/initializers/add-store-name-to-post", ["exports", "@ember/utils", "discourse/lib/plugin-api"], function (_exports, _utils, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function addStoreName(api) {
    api.addPosterIcon(cfs => {
      if (cfs) {
        const shopName = cfs.shop_name;
        const shopUrl = cfs.shop_url;
        if (!(0, _utils.isEmpty)(shopName) && !(0, _utils.isEmpty)(shopUrl)) {
          return {
            icon: "shopping-cart",
            url: shopUrl,
            text: shopName,
            className: "user-store"
          };
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "add-store-name-to-post",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", addStoreName);
    }
  };
});